import type { RouteLocationNormalized } from 'vue-router'
import useApi from '@/composables/useApi'

const access = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const api = useApi()

  if (to.meta.access && api.getUserType() !== to.meta.access) {
    return {
      name: 'landing'
    }
  }
}

export default access
