import get from 'lodash/get'
import { computed, reactive, ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import useBrowserStorage from '@/composables/useBrowserStorage'
import type { Practice } from '@/interfaces/models/Practice'

const useBusinessStore = defineStore('business', () => {
  const [getBusinessId, setBusinessId, removeBusinessId] = useBrowserStorage('businessId')
  const api = useApi()

  const practices = ref<Practice[]>([])
  const practiceId = ref<string|undefined>()
  const practice = computed<Practice | undefined>(() => practices.value.find(item => item.id === practiceId.value))
  const loading = reactive({
    practice: false
  })

  const fetchPractices = async (force = false) => {
    if (practices.value.length && !force) {
      return
    }
    loading.practice = true
    const [, res] = await api.get.dashboard.practiceAll()
    if (res) {
      practices.value = get(res, 'data.data', [])
      if (!practiceId.value) {
        practiceId.value = get(practices.value, '0.id', undefined)
      }
    }
    loading.practice = false
  }

  return {
    getBusinessId,
    setBusinessId,
    removeBusinessId,
    fetchPractices,
    practiceId,
    practice,
    practices,
    loading
  }
})

export default useBusinessStore
