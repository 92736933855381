import type { Component } from 'vue'
import { shallowRef, ref, nextTick } from 'vue'
import { defineStore } from 'pinia'

const useDialogStore = defineStore('dialog', () => {
  const dialog = shallowRef<Component | null>(null)
  const props = ref<object>({})
  const open = ref<boolean>(false)

  const show = (prefab: Component, modalProps?: object) => {
    return new Promise<void>((resolve) => {
      dialog.value = prefab
      props.value = modalProps || {}
      open.value = true
      void nextTick(() => {
        resolve()
      })
    })
  }

  const hide = async (): Promise<void> => {
    return new Promise<void>((resolve) => {
      open.value = false
      setTimeout(() => {
        dialog.value = null
        props.value = {}
        void nextTick(() => {
          resolve()
        })
      }, 200)
    })
  }

  return {
    dialog,
    props,
    open,
    show,
    hide
  }
})

export default useDialogStore
