import { reactive } from "vue";
import API from "@/api/ApiActions.js";

const $ = reactive({
  name: "alizoka",
  global_notif: false,
  global_notif_color: "",
  global_notif_timeout: 2000,
  signedUpWithGoogle: false,
  notifText: "",
  user_info: {},
  clickedOutSide: false,
  signup_data: {
    insuranceName: "",
    insuranceNumber: "",
    gender: "male",
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: null,
    email: "",
    phone: "",
    password: "",
    re_password: "",
    ott: "",
  },
  goNotif: (notif_data) => {
    $.notifText = notif_data.notifText;
    $.global_notif_color = notif_data.global_notif_color;
    $.global_notif_timeout = notif_data.global_notif_timeout;
    $.global_notif_variant = notif_data.global_notif_variant;
    $.global_notif = true;
  },

  reverseTimer: (seconds) => {
    let sec = seconds;
    const myInterval = setInterval(() => {
      // console.log(seconds);
      if (seconds > 0) {
        seconds--;
      } else {
        clearInterval(myInterval);
      }
    }, 1000);
    return seconds;
  },

  formatSecondsToHHMM: (secs) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  },
  base64DeviceInfo: null,
  getXDeviceInfo: () => {
    const uuidv4 = () => {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    };

    let deviceId = localStorage.getItem("deviceId");

    if (!deviceId) {
      // Generate a random UUID as the device ID
      deviceId = uuidv4();

      // Store the generated device ID in localStorage for future use
      localStorage.setItem("deviceId", deviceId);
    }
    const browserInfo = navigator.userAgent;
    const os =
      ["Windows", "Android", "Mac", "Linux"].find((v) =>
        browserInfo.indexOf(v)
      ) ?? "Unknown";
    let browser;
    if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
      browser = "Opera";
    } else if (browserInfo.includes("Edg")) {
      browser = "Edge";
    } else if (browserInfo.includes("Chrome")) {
      browser = "Chrome";
    } else if (browserInfo.includes("Safari")) {
      browser = "Safari";
    } else if (browserInfo.includes("Firefox")) {
      browser = "Firefox";
    } else {
      browser = "unknown";
    }
    const deviceInfo = {
      osName: os,
      deviceModelName: browser,
      deviceId: deviceId,
      carrierName: "1",
      osVersion: "0",
      deviceName: "Browser",
      appVersion: "1.0.0",
    };
    const deviceInfoString = JSON.stringify(deviceInfo);
    const base64DeviceInfo = btoa(deviceInfoString);
    $.base64DeviceInfo = base64DeviceInfo;
  },

  API,
});

export default $;
