import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import * as Sentry from '@sentry/vue'
import { registerPlugins } from '@/plugins'
import App from '@/App.vue'
import router from '@/router'
import '@/assets/styles.scss'

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/emergclinic\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router)

  app.use(VueGtag, {
    appName: import.meta.env.VITE_GOOGLE_ANALYTICS_APP_NAME,
    pageTrackerScreenviewEnabled: true,
    config: {
      id: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID
    },
    bootstrap: true
  })


// if (import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID) {
//   app.use(createGtm({
//     id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
//   }))
// }
registerPlugins(app)

app.mount('#app')
