import axios from 'axios'
import objGet from 'lodash/get'
import { useMemoize } from '@vueuse/core'
import useBrowserStorage from '@/composables/useBrowserStorage'
import useTo from '@/composables/useTo'
import useDevice from '@/composables/useDevice'
import queryStringify from '@/utils/queryStringify'
import { To } from '@/interfaces/To'

interface CustomAxiosRequestConfig extends axios.AxiosRequestConfig {
  _retry?: boolean
}

export type Api = (...param: any[]) => To
export type ApiRecord = Record<string, Record<string, Api>>

const useApi = () => {
  const [getStorageToken, setStorageToken, removeStorageToken] = useBrowserStorage('token')
  const [getStorageRefreshToken, setStorageRefreshToken, removeStorageRefreshToken] = useBrowserStorage('refreshToken')
  const [getUserType, setUserType, removeUserType] = useBrowserStorage('userType')
  const to = useTo()
  const device = useDevice()
  const userRefreshTokenPath = '/v1/auth/user/refreshToken'
  const dashboardRefreshTokenPath = '/v1/auth/dashboard/refreshToken'

  const setToken = (token?: string) => {
    if (!token) {
      removeStorageToken()
      removeStorageRefreshToken()
      removeUserType()
      return
    }
    setStorageToken(token)
  }

  const setRefreshToken = (refreshToken) => {
    setStorageRefreshToken(refreshToken)
  }

  const onRequest = (config: CustomAxiosRequestConfig): CustomAxiosRequestConfig => {
    const storageToken = getStorageToken()
    Object.assign(config.headers, {
      Authorization: storageToken ? `Bearer ${storageToken}` : undefined,
      'X-Device-Info': device.getDeviceHash()
    })

    return config
  }

  const onError = async (error: axios.AxiosError) => {
    const requestConfig: CustomAxiosRequestConfig = error.config
    if (
      error.response.status === 401 &&
      (requestConfig.url.endsWith(userRefreshTokenPath) || requestConfig.url.endsWith(dashboardRefreshTokenPath))
    ) {
      setToken()
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !requestConfig._retry) {
      requestConfig._retry = true
      const refreshToken = getStorageRefreshToken()
      const [, res] = refreshToken ? await refreshTokenMemoize(refreshToken) : [undefined, undefined];
      const token = objGet(res, 'data.token', undefined)
      const newRefreshToken = objGet(res, 'data.refreshToken', undefined)
      newRefreshToken && setRefreshToken(newRefreshToken)

      if (token) {
        setToken(token)
        requestConfig.headers.Authorization = `Bearer ${token}`
        return axiosInstance(requestConfig)
      }
    }

    return Promise.reject(error)
  }

  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BASE_API,
    headers: {
      'Content-Type': 'application/json',
    }
  })
  axiosInstance.interceptors.response.use(response => response, onError)
  // noinspection TypeScriptValidateTypes
  axiosInstance.interceptors.request.use(onRequest, (error: axios.AxiosError) => Promise.reject(error))

  const get: ApiRecord = {
    user: {
      me: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/user/me'))),
      treatments: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/user/treatment'))),
      bookingTimeRanges: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/user/time-management/booking-time-range'))),
      appointmentOffer: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/user/appointment-offer'))),
      appointmentRequest: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/user/appointment-request'))),
      appointment: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/user/appointment'))),
    },
    admin: {
      me: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/admin/me'))),
      generalInfo: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/admin/general-info')))
    },
    dashboard: {
      practiceAll: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/practice'))),
      practice: (id: string, query = {}) => to(axiosInstance.get(queryStringify(query, `/v1/dashboard/practice/${id}`))),
      appointmentRequests: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/appointment-request'))),
      appointmentRequest: (id: string, query = {}) => to(axiosInstance.get(queryStringify(query, `/v1/dashboard/appointment-request/${id}`))),
      fileImage: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/file/image'))),
      appointments: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/appointment'))),
      appointment: (id: string, query = {}) => to(axiosInstance.get(queryStringify(query, `/v1/dashboard/appointment/${id}`))),
      paymentCredential: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/payment/credential'))),
      appointmentOffer: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/appointment-offer'))),
      wallet: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/wallet'))),
      walletTransactions: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/wallet/transactions'))),
      walletBonus: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/wallet/bonus'))),
      analyticsCredit: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/analytics/credit'))),
      analyticsAppointment: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/analytics/appointment'))),
      analyticsPractice: (query = {}) => to(axiosInstance.get(queryStringify(query, '/v1/dashboard/analytics/practice'))),
    }
  }

  const post: ApiRecord = {
    user: {
      login: (body = {}) => to(axiosInstance.post('/v1/auth/user/login', body)),
      refreshToken: (body: {}) => to(axiosInstance.post(userRefreshTokenPath, body)),
      appointmentRequest: (body = {}) => to(axiosInstance.post('/v1/user/appointment-request', body)),
      appointmentRequestConfirm: (id: string, body = {}) => to(axiosInstance.post(`/v1/user/appointment-request/${id}/confirm`, body)),
      createOTT: (body = {}) => to(axiosInstance.post('/v1/auth/user/create-ott', body)),
      googleSignIn: (body = {}) => to(axiosInstance.post('/v1/auth/user/google/sign-in', body)),
      appleSignIn: (body = {}) => to(axiosInstance.post('/v1/auth/user/apple/sign-in', body)),
      signUp: (body = {}) => to(axiosInstance.post('/v1/user/sign-up', body)),
    },
    dashboard: {
      login: (body = {}) => to(axiosInstance.post('/v1/auth/dashboard/login', body)),
      refreshToken: (body = {}) => to(axiosInstance.post(dashboardRefreshTokenPath, body)),
      practiceAvatar: (id: string, body = {}) => to(axiosInstance.post(`/v1/dashboard/practice/${id}/avatar`, body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })),
      appointmentOffer: (body = {}) => to(axiosInstance.post('/v1/dashboard/appointment-offer', body)),
      practiceSignUp: (body = {}) => to(axiosInstance.post('/v1/dashboard/practice/sign-up', body)),
      fileImage: (body = {}) => to(axiosInstance.post('/v1/dashboard/file/image', body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })),
      dashboardPaymentStripeCard: (body = {}) => to(axiosInstance.post('/v1/dashboard/payment/stripe/card', body)),
    }
  }

  const patch: ApiRecord = {
    user: {
      appointmentRequestCancel: (id: string) => to(axiosInstance.patch(`/v1/user/appointment-request/${id}/cancel`)),
      appointment: (id: string, body = {}) => to(axiosInstance.patch(`/v1/user/appointment/${id}`, body)),
      user: (id: string, body = {}) => to(axiosInstance.patch(`/v1/user/${id}`, body)),
    },
    dashboard: {
      practice: (id: string, body = {}) => to(axiosInstance.patch(`/v1/dashboard/practice/${id}`, body)),
      appointment: (id: string, body = {}) => to(axiosInstance.patch(`/v1/dashboard/appointment/${id}`, body)),
      walletBonusClaim: (body = {}) => to(axiosInstance.patch('/v1/dashboard/wallet/bonus/claim', body)),
    }
  }

  const deleteEndpoints: ApiRecord = {
    dashboard: {
      offer: (id: string) => to(axiosInstance.delete(`/v1/dashboard/appointment-offer/${id}`)),
      practiceAvatar: (id: string) => to(axiosInstance.delete(`/v1/dashboard/practice/${id}/avatar`)),
      fileImage: (id: string, body = {}) => to(axiosInstance.delete(`/v1/dashboard/file/image/${id}`, {
        data: body
      }))
    }
  }

  const refreshTokenMemoize = useMemoize(async (refreshToken: string) => {
    const userType = getUserType()
    const refreshTokenApi = userType === 'patient' ? post.user.refreshToken : post.dashboard.refreshToken
    return await refreshTokenApi({ refreshToken })
  })

  return {
    get,
    post,
    patch,
    delete: deleteEndpoints,
    setToken,
    setRefreshToken,
    getUserType,
    setUserType: (type: 'patient' | 'provider') => setUserType(type),
  }
}

export default useApi
