import { ref } from 'vue'
import { defineStore } from 'pinia'
import get from 'lodash/get'
import useApi from '@/composables/useApi'
import useToast from '@/store/useToast'
import type { User } from '@/interfaces/models/User'
import type { LoginPatientDto } from '@/interfaces/dto/LoginPatientDto'
import type { LoginProviderDto } from '@/interfaces/dto/LoginProviderDto'
import useBusinessStore from '@/store/useBusinessStore'

const useAuthStore =  defineStore('auth', () => {
  const api = useApi()
  const toast = useToast()
  const businessStore = useBusinessStore()

  const user = ref<User|undefined>()

  const fetchUser = async () => {
    const userType = api.getUserType()
    if (userType === 'patient') {
      const [, res] = await api.get.user.me()
      user.value = get(res, 'data', undefined)
      return
    }
    if (userType === 'provider') {
      const [, res] = await api.get.admin.me()
      user.value = get(res, 'data', undefined)
      return
    }
    user.value = undefined
  }

  const loginPatient = async (dto: LoginPatientDto): Promise<boolean> => {
    const [err, res] = await api.post.user.login(dto)
    err && toast.apiErrorToast(err)
    const token = get(res, 'data.token', undefined)
    const user = get(res, 'data.user', undefined)
    if (token && user) {
      api.setUserType('patient')
      api.setToken(token)
      api.setRefreshToken(get(res, 'data.refreshToken', undefined))
      setUser(user)
      return true
    }
    return false
  }

  const loginProvider = async (dto: LoginProviderDto): Promise<boolean> => {
    const [err, res] = await api.post.dashboard.login(dto)
    err && toast.apiErrorToast(err)
    const token = get(res, 'data.token', undefined)
    const user = get(res, 'data.admin', undefined)
    const businessId = get(res, 'data.admin.businessId')
    if (token && user && businessId) {
      api.setUserType('provider')
      api.setToken(token)
      api.setRefreshToken(get(res, 'data.refreshToken', undefined))
      user.value = user
      businessStore.setBusinessId(businessId)
      return true
    }
    return false
  }

  const logout = () => {
    api.setToken()
    user.value = undefined
    businessStore.removeBusinessId()
    return true
  }

  const setUser = (usr) => {
    user.value = usr
  }

  return {
    user,
    fetchUser,
    setUser,
    logout,
    loginPatient,
    loginProvider
  }
})

export default useAuthStore
