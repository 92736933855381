import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router"
import useGlobalStore from '@/store/useGlobalStore'
import auth from '@/middlewares/auth'
import access from '@/middlewares/access'
import { pageview } from 'vue-gtag'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'landing',
    meta: {
      title: 'Emergclinic Home',
    },
    component: () => import('@/pages/Index.vue')
  },
  {
    path: '/apple-callback',
    name: 'apple-callback',
    meta: {
      title: 'Apple Sign In',
    },
    component: () => import('@/pages/Apple.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    meta: {
      title: 'About Emergclinic',
    },
    component: () => import('@/pages/AboutUs.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
    children: [
      {
        path: '',
        name: 'login-home',
        meta: {
          guest: true,
          title: 'Login Patient'
        },
        component: () => import('@/components/login/Patient.vue')
      },
      {
        path: 'patient',
        name: 'login-patient',
        meta: {
          guest: true,
          title: 'Login Patient'
        },
        component: () => import('@/components/login/Patient.vue')
      },
      {
        path: 'provider',
        name: 'login-provider',
        meta: {
          guest: true,
          title: 'Login Clinic'
        },
        component: () => import('@/components/login/Provider.vue')
      }
    ]
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/components/signup/Index.vue'),
    children: [
      {
        path: 'patient',
        name: 'signup-patient',
        meta: {
          title: 'Sign Up Patient'
        },
        children: []
      },
      {
        path: 'clinic',
        name: 'signup-clinic',
        meta: {
          title: 'Sign Up Clinic'
        },
        children: []
      }
    ]
  },
  {
    path: '/signup/patient/account',
    name: 'signup-patient-account',
    meta: {
      title: 'Sign Up - Patient Info'
    },
    component: () => import('@/components/signup/patient/Info.vue')
  },
  {
    path: '/signup/patient/details',
    name: 'signup-patient-details',
    meta: {
      title: 'Sign Up - Patient Details'
    },
    component: () => import('@/components/signup/patient/Details.vue')
  },
  {
    path: '/patient/dashboard',
    component: () => import('@/layouts/PatientDashboard.vue'),
    children: [
      {
        path: '',
        name: 'patient-dashboard',
        meta: {
          auth: true,
          access: 'patient',
          title: 'Patient Dashboard'
        },
        component: () => import('@/pages/patient/Index.vue')
      },
      {
        path: 'find-clinic',
        name: 'patient-dashboard-find-clinic',
        meta: {
          auth: true,
          access: 'patient',
          title: 'Patient Dashboard - Find Clinic'
        },
        component: () => import('@/pages/patient/FindClinic.vue')
      },
      {
        path: 'appointments',
        name: 'patient-dashboard-appointments',
        meta: {
          auth: true,
          access: 'patient',
          title: 'Patient Dashboard - Appointments'
        },
        component: () => import('@/pages/patient/Appointments.vue')
      },
      {
        path: 'profile-information',
        name: 'patient-dashboard-profile-information',
        meta: {
          auth: true,
          access: 'patient',
          title: 'Patient Dashboard - Profile Information'
        },
        component: () => import('@/pages/patient/ProfileInformation.vue'),
      },
      {
        path: 'insurance',
        name: 'patient-dashboard-insurance',
        meta: {
          auth: true,
          access: 'patient',
          title: 'Patient Dashboard - Insurance'
        },
        component: () => import('@/pages/patient/Insurance.vue'),
      },
      {
        path: 'faqs',
        name: 'patient-dashboard-faqs',
        meta: {
          auth: true,
          access: 'patient',
          title: 'Patient Dashboard - FAQ'
        },
        component: () => import('@/pages/patient/Faqs.vue'),
      }
    ]
  },
  {
    path: '/provider/dashboard',
    component: () => import('@/layouts/ClinicDashboard.vue'),
    children: [
      {
        path: '',
        name: 'provider-dashboard',
        meta: {
          auth: true,
          access: 'provider',
          title: 'Clinic Dashboard'
        },
        component: () => import('@/pages/clinic/Index.vue')
      },
      {
        path: 'appointments',
        name: 'provider-dashboard-appointments',
        meta: {
          auth: true,
          access: 'provider',
          title: 'Clinic Dashboard - Appointments'
        },
        component: () => import('@/pages/clinic/Appointments.vue')
      },
      {
        path: 'explore-appointments',
        name: 'provider-dashboard-explore-appointments',
        meta: {
          auth: true,
          access: 'provider',
          title: 'Clinic Dashboard - Explore Appointments'
        },
        component: () => import('@/pages/clinic/ExploreAppointments.vue')
      },
      {
        path: 'financial',
        name: 'provider-dashboard-financial',
        meta: {
          auth: true,
          access: 'provider',
          title: 'Clinic Dashboard - Financial'
        },
        component: () => import('@/pages/clinic/Financial.vue')
      },
      {
        path: 'pricing-faq',
        name: 'provider-dashboard-pricing-faq',
        meta: {
          auth: true,
          access: 'provider',
          title: 'Clinic Dashboard - FAQ'
        },
        component: () => import('@/pages/clinic/Faqs.vue')
      },
      {
        path: 'demo-video',
        name: 'provider-dashboard-demo-video',
        meta: {
          auth: true,
          access: 'provider',
          title: 'Clinic Dashboard - Demo Video'
        },
        component: () => import('@/pages/errors/404.vue')
      },
      {
        path: 'settings',
        name: 'provider-dashboard-settings',
        component: () => import('@/pages/clinic/settings/Index.vue'),
        children: [
          {
            path: '',
            name: 'provider-dashboard-settings-information',
            meta: {
              auth: true,
              access: 'provider',
              title: 'Clinic Dashboard - Information Settings'
            },
            component: () => import('@/pages/clinic/settings/Information.vue'),
          },
          {
            path: 'insurance',
            name: 'provider-dashboard-settings-insurance',
            meta: {
              auth: true,
              access: 'provider',
              title: 'Clinic Dashboard - Insurance Settings'
            },
            component: () => import('@/pages/clinic/settings/Insurance.vue'),
          },
          {
            path: 'treatments',
            name: 'provider-dashboard-settings-treatments',
            meta: {
              auth: true,
              access: 'provider',
              title: 'Clinic Dashboard - Treatment Settings'
            },
            component: () => import('@/pages/clinic/settings/Treatments.vue'),
          },
          {
            path: 'payment',
            name: 'provider-dashboard-settings-payment',
            meta: {
              auth: true,
              access: 'provider',
              title: 'Clinic Dashboard - Payment Settings'
            },
            component: () => import('@/pages/clinic/settings/Payment.vue'),
          },
          {
            path: 'gallery',
            name: 'provider-dashboard-settings-gallery',
            meta: {
              auth: true,
              access: 'provider',
              title: 'Clinic Dashboard - Gallery Settings'
            },
            component: () => import('@/pages/clinic/settings/Gallery.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      title: 'Page Not Found'
    },
    component: () => import('@/pages/errors/404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to) => {
  if (import.meta.env.PROD) {
    pageview({ page_path: to.fullPath })
  }
  const globalStore = useGlobalStore()
  globalStore.routerLoading = false
})

router.beforeEach(async (...attrs) => {
  document.title = attrs[0].meta.title || 'Emergclinic';
  const globalStore = useGlobalStore()
  globalStore.routerLoading = true
  const authRedirect = await auth(...attrs)
  if (authRedirect) {
    return authRedirect
  }

  const accessRedirect = await access(...attrs)
  if (accessRedirect) {
    return accessRedirect
  }
})

export default router
