import queryString from 'query-string'
import type { QueryArrayFormat } from '@/interfaces/QueryArrayFormat'

const queryStringify = (query: Record<string, any>, url?: string, arrayFormat: QueryArrayFormat = 'none') => {
  if (url) {
    return queryString.stringifyUrl({ url, query }, { arrayFormat })
  }
  return queryString.stringify(query, { arrayFormat })
}

export default queryStringify
