import axios from "axios";
import $ from "@/store/store";

const axiosInstance = axios.create({
  // baseURL: import.meta.env.VITE_BASE_API,
  baseURL: "https://dev.smilelink.app/api",
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET",
  // },
});

async function get(endpoint, params) {
  try {
    const response = await axiosInstance.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.log("api error is:", error);

    if (!!error?.response?.data?.messages?.length) {
      error.response.data.messages.forEach((msg) => {
        $.goNotif({
          notifText: msg.message,
          global_notif_color: "pink darken-3",
        });
      });
    } else {
      $.goNotif({
        notifText: "Oops...there's a Problem!",
        global_notif_color: "red",
      });
    }
  }
}

async function post(endpoint, data) {
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.log("api error is:", error);
    if (!!error?.response?.data?.messages?.length) {
      error.response.data.messages.forEach((msg) => {
        $.goNotif({
          notifText: msg.message,
          global_notif_color: "pink darken-3",
        });
      });
    } else {
      $.goNotif({
        notifText: error.message,
        global_notif_color: "pink darken-3",
      });
    }
  }
}
async function patch(endpoint, data) {
  try {
    const response = await axiosInstance.patch(endpoint, data);
    return response.data;
  } catch (error) {
    console.log("api error is:", error);
    if (!!error?.response?.data?.messages?.length) {
      error.response.data.messages.forEach((msg) => {
        $.goNotif({
          notifText: msg.message,
          global_notif_color: "pink darken-3",
        });
      });
    } else {
      $.goNotif({
        notifText: error.message,
        global_notif_color: "pink darken-3",
      });
    }
  }
}
async function put(endpoint, data) {
  const response = await axiosInstance.put(endpoint, data);
  return response.data;
}

async function del(endpoint) {
  const response = await axiosInstance.delete(endpoint);
  return response.data;
}

function setAuthTokenToAxios(token) {
  if (!!token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
}
function setDeviceInfoToAxios(deviceInfo) {
  axiosInstance.defaults.headers.common["X-Device-Info"] = deviceInfo;
  // "Access-Control-Allow-Origin": '*'
  // "Access-Control-Allow-Methods": 'GET',
}

export default {
  get,
  post,
  put,
  patch,
  setAuthTokenToAxios,
  setDeviceInfoToAxios,
  delete: del,
};
