import { defineStore } from 'pinia'
import { ref } from 'vue'
import useApi from '@/composables/useApi'
import type { GlobalData } from '@/interfaces/GlobalData'
import type { Treatment } from '@/interfaces/models/Treatment'
import type { TimeRange } from '@/interfaces/models/TimeRange'

const useGlobalStore = defineStore('global', () => {
  const api = useApi()

  const routerLoading = ref<boolean>(false)

  const treatments = ref<GlobalData<Treatment>>({
    loading: false,
    items: []
  })

  const bookingTimeRanges = ref<GlobalData<TimeRange>>({
    loading: false,
    items: []
  })

  const loadTreatments = async () => {
    if (treatments.value.items.length > 2 || treatments.value.loading) {
      return
    }
    treatments.value.loading = true
    const [, res] = await api.get.user.treatments()
    treatments.value.items = (res?.data || []).sort((a, b) => (b.type ? 1 : 0) - (a.type ? 1 : 0))
    treatments.value.loading = false
  }

  const loadBookingTimeRanges = async () => {
    if (bookingTimeRanges.value.items.length || bookingTimeRanges.value.loading) {
      return
    }
    bookingTimeRanges.value.loading = true
    const [, res] = await api.get.user.bookingTimeRanges()
    bookingTimeRanges.value.items = (res?.data?.data || []) as TimeRange[]
    bookingTimeRanges.value.loading = false
  }

  const newAppointment = ref<boolean>(false)

  return {
    routerLoading,
    treatments,
    bookingTimeRanges,
    newAppointment,
    loadTreatments,
    loadBookingTimeRanges
  }
})

export default useGlobalStore
