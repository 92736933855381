<template>
  <v-app>
    <v-progress-linear
      v-if="globalStore.routerLoading"
      indeterminate
      color="primary"
      style="position: fixed; left: 0; top: 0; right: 0; z-index: 10000"
    />
    <suspense>
      <RouterView />
    </suspense>
    <DialogsContainer />
    <v-snackbar
      v-model="$.global_notif"
      :timeout="$.global_notif_timeout"
      location="right"
      :color="$.global_notif_color"
      :variant="$.global_notif_variant"
    >
      <div class="">
        {{ $.notifText }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="toast.toast.show"
      :key="toast.toast.key"
      :timeout="toast.toast.timeout"
      :color="toast.toast.color"
      variant="elevated"
      location="right"
    >
      {{ toast.toast.message }}
    </v-snackbar>
  </v-app>
</template>

<script setup lang="ts">
import { RouterView } from "vue-router";
import $ from "@/store/store";
import APIService from "@/api/APIService.js";
import useToast from '@/store/useToast'
import useGlobalStore from '@/store/useGlobalStore'

const toast = useToast()
const globalStore = useGlobalStore()

$.getXDeviceInfo();
APIService.setDeviceInfoToAxios($.base64DeviceInfo);
const token = localStorage.getItem("token");
APIService.setAuthTokenToAxios(token);
</script>
