import APIService from "@/api/APIService.js";
import axios from "axios";

export const mainApi = {
  createOTT: async (payload) => {
    return APIService.post("/v1/auth/user/create-ott", payload)
      .then((res) => res)
      .catch((err) => err);
  },
  signUp: async (payload) => {
    return APIService.post("/v1/user/sign-up", payload)
      .then((res) => res)
      .catch((err) => err);
  },
  signUpDentalClinic: async (payload) => {
    return APIService.post("/v1/dashboard/practice/sign-up", payload)
      .then((res) => res)
      .catch((err) => err);
  },
  uploadClinicPictures: async (payload) => {
    return APIService.post("/v1/dashboard/file/image", payload)
      .then((res) => res)
      .catch((err) => err);
  },
  savePaymentInfo: async (payload) => {
    return APIService.post("/v1/dashboard/payment/information", payload)
      .then((res) => res)
      .catch((err) => err);
  },
  getAppointment: async (params) => {
    return APIService.get("/v1/user/appointment-request", params)
      .then((res) => res)
      .catch((err) => err);
  },
  saveAppointments: async (payload) => {
    return APIService.post("/v1/user/appointment-request", payload)
      .then((res) => res)
      .catch((err) => err);
  },
  getTreatments: async (params) => {
    return APIService.get("/v1/user/treatment", params)
      .then((res) => res)
      .catch((err) => err);
  },
  getBookingTimes: async (params) => {
    return APIService.get("/v1/user/time-management/booking-time-range", params)
      .then((res) => res)
      .catch((err) => err);
  },
  getMe: async () => {
    return APIService.get("/v1/user/me")
      .then((res) => res)
      .catch((err) => err);
  },
  sendTokenStripe: async (payload) => {
    return APIService.post("/v1/dashboard/payment/stripe/card", payload)
      .then((res) => res)
      .catch((err) => err);
  },
  googleGetToken: async (payload) => {
    return APIService.post("/v1/auth/user/google/sign-in", payload)
      .then((res) => res)
      .catch((err) => err);
  },
  patchUser: async (userId, payload) => {
    return APIService.patch(`/v1/user/${userId}`, payload)
      .then((res) => res)
      .catch((err) => err);
  },
  getGeoLocationData: async (zipCode) => {
    const url =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      zipCode +
      "&key=AIzaSyCf98IwQ2m6X0H6udTuFobDpmdi2fGBpv8";
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return error;
    }
  },
  getAddressSuggestionByNameOld: async (address) => {
    let url =
      "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" +
      address +
      "&language=en&types=geocode&key=AIzaSyCf98IwQ2m6X0H6udTuFobDpmdi2fGBpv8";
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return error;
    }
  },

  getAddressSuggestionByName: async (address) => {
    const apiUrl = "https://places.googleapis.com/v1/places:autocomplete";
    const params = {
      input: address,
    };
    try {
      const response = await axios.post(apiUrl, params, {
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": import.meta.env.VITE_GOOGLE_API_KEY,
        },
      });
      console.log(response.data.suggestions);
      return response.data.suggestions;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
