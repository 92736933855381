import type { App } from 'vue'
import { vMaska } from 'maska/vue'
import { Icon } from '@iconify/vue'
import vuetify from '@/plugins/vuetify'
import pinia from '@/plugins/pinia'
import VueApexCharts from "vue3-apexcharts";

export function registerPlugins (app: App) {
  app.use(vuetify)
  app.use(pinia)
  app.use(VueApexCharts)
  app.component('Icon', Icon)
  app.directive('mask', vMaska)
}
