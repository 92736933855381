import { v4 as uuidV4 } from 'uuid'
import UAParser from 'ua-parser-js'
import useBrowserStorage from '@/composables/useBrowserStorage'

const useDevice = () => {
  const [getStorageDeviceId, setStorageDeviceId] = useBrowserStorage('deviceId')
  const getDeviceId = () => {
    let deviceId = getStorageDeviceId()
    if (!deviceId) {
      deviceId = uuidV4()
      setStorageDeviceId(deviceId)
    }
    return deviceId
  }

  const getDeviceInfo = () => {
    const parser = new UAParser(navigator.userAgent)
    const os = parser.getOS()
    return {
      osName: os?.name || 'unknown',
      osVersion: os?.version || 'unknown',
      deviceModelName: parser.getBrowser()?.name || 'unknown',
      deviceName: 'Browser',
      carrierName: '1',
      appVersion: '1.0.0'
    }
  }

  const getDeviceHash = () => {
    const deviceId = getDeviceId()
    const deviceInfo = getDeviceInfo()

    return btoa(JSON.stringify({
      deviceId,
      ...deviceInfo
    }))
  }

  return {
    getDeviceId,
    getDeviceInfo,
    getDeviceHash
  }
}

export default useDevice
