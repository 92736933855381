import { defineStore } from 'pinia'
import get from 'lodash/get'
import { reactive } from 'vue'

interface Toast {
  key: number,
  show: boolean
  message?: string
  color?: string,
  timeout: number
}

const useToast = defineStore('toast', () => {
  const toast = reactive<Toast>({
    key: 0,
    show: false,
    message: undefined,
    color: 'primary',
    timeout: 5000
  })

  const successToast = (message: string) => {
    toast.message = message
    toast.color = 'primary'
    toast.key++
    toast.show = true
  }

  const errorToast = (message: string) => {
    toast.message = message
    toast.color = 'pink darken-3'
    toast.key++
    toast.show = true
  }

  const apiErrorToast = (error) => {
    let message = get(error, 'response.data.messages.0.message')
    message = message || get(error, 'response.data.message')
    message && errorToast(message)
  }

  return {
    toast,
    successToast,
    errorToast,
    apiErrorToast
  }
})

export default useToast
