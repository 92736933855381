import type { RouteLocationNormalized } from 'vue-router'
import useAuthStore from '@/store/useAuthStore'
import useApi from '@/composables/useApi'

const auth = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const authStore = useAuthStore()
  const api = useApi()
  const userType = api.getUserType()
  userType && await authStore.fetchUser()

  if (!authStore.user && to.meta.auth) {
    return {
      name: 'login'
    }
  }

  if (authStore.user && to.meta.guest) {
    return {
      name: userType === 'patient' ? 'patient-dashboard' : 'provider-dashboard'
    }
  }
}

export default auth
