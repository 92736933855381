import type { AxiosError } from 'axios'
import type { To } from '@/interfaces/To'

const useTo = () => {
  return async <R extends object, E extends object = object>(promise: Promise<any>, mapper?: (data: any) => R): To<R, E> => {
    try {
      const res = await promise
      return [undefined, (mapper ? mapper(res) : res) as R]
    } catch (err: any) {
      if (mapper && err && err.response?._data) {
        err.response._data = mapper(err.response?._data)
      }
      return [err as AxiosError<E>, undefined]
    }
  }
}

export default useTo
