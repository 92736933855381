<template>
  <component
    v-if="dialogStore.dialog"
    :is="dialogStore.dialog"
    v-bind="{...(dialogStore.props || {}), modelValue: dialogStore.open}"
    v-on="{ 'update:model-value': onUpdateModelValue, close: () => onUpdateModelValue(false) }"
  />
</template>

<script setup lang="ts">
import useEmitter from '@/composables/useEmitter'
import useDialogStore from '@/store/useDialogStore'

const dialogStore = useDialogStore()
const emitter = useEmitter()

const onUpdateModelValue = (value) => {
  if (!value) {
    dialogStore.hide()
    emitter.emit('dialog-close')
  }
}
</script>
